@import "../../assets/styles/global/_style_elements";

.load-container {
  &.fixed, &.absolute {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.fixed {
    position: fixed !important;
  }

  &.absolute {
    position: absolute !important;
  }

  &.dark {
    .loader:before {
      background-color: rgba(#222, 0.85);
    }
  }

  .loader {
    $size: 50px;

    position: relative;
    overflow: hidden;
    background-image: url('../../assets/images/moon.svg');
    transform: translateZ(0);

    &, &:before {
      width: $size;
      height: $size;
      border-radius: 50%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($page-bg, 0.8);
      transform: translateX(-100%);
      animation: phase 2s infinite linear;
    }
  }
}

@keyframes phase {
  to {
    transform: translateX(100%);
  }
}
