@import "../../assets/styles/global/_style_elements";
@import "../../assets/styles/mixins/mixins";

@mixin set-link-color($color){
  a:not(.skip-link-style){
    &:after {
      background-color: $color;
    }
    @include hover-focus-helper {
      color: $color;
    }
  }
}

@mixin set-theme-els($link-color, $selection-bg, $thumb, $bg1, $bg2) {
  @include set-link-color($link-color);

  ::selection {
    background: $selection-bg;
  }

  ::-moz-selection {
    background: $selection-bg;
  }

  .header-container.nav:not(.skip-theme) { background-color: rgba($thumb, 0.9); }
  footer { background-color: $thumb; }
  .bg-fill { @include page-background($bg1, $bg2); }
  .content-container-grid .content-item .thumb{ background-color: $thumb; }
  .detail {
    .title, .content-info a {
      color: $link-color;
    }
  }
}

.theme {
  &-supplement {
    &-day{
      @include set-link-color($day-accent-text);
    }
    &-day.theme-afternoon {
      @include set-theme-els($afternoon-accent-text, $afternoon-base, $afternoon-thumb, $afternoon-base, $afternoon-accent);
    }

    &-night{
      @include set-theme-els($night-accent-text, $night-thumb, $night-thumb, $night-base, $night-accent);
      $selection-color: $light-text;

      ::selection {
        color: $selection-color;
      }

      ::-moz-selection {
        color: $selection-color;
      }

      h1 .accent,
      h2:not(.section-title),
      h3, footer,
      .content-container.skills,
      .header-container.nav:not(.skip-theme),
      .header-container.nav:not(.skip-theme) a,
      .connect-icon-label {
        color: $night-text;
      }

      .content-container-grid .content-item .thumb{
        .icon {
          background-position: 50% 100%;
        }
      }
      .header-container.nav:not(.skip-theme) {
        border-bottom: 1px solid rgba(#fff, 0.15);
      }

      .content-container.about{ color: $day-text; }

      .header-container:not(.nav), .header-container.nav:not(.skip-theme), footer {
        .connect .icon {
          @include sprite-position(2, 5 2, $connect-icons);
        }
      }

      footer, .browse .header {
        .arrow {
          background-position: 0 100%;
        }
      }

      &.theme-evening{
        @include set-theme-els($evening-accent-text, $evening-base, $evening-thumb, $evening-base, $evening-accent);
      }
      &.theme-midnight{
        @include set-theme-els($midnight-accent-text, $midnight-thumb, $midnight-thumb, $midnight-base, $midnight-accent);
      }
    }
  }
}
