@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/mixins/mixins";
@import "../../assets/styles/global/_style_elements";
@import "../../assets/styles/mixins/icon_sprite";

$trans: 250ms;

.connect {
  a {
    display: inline-block;
    & + a {
      margin-left: 1.5em;
    }

    @include hover-focus-helper {
      .icon {
        transform: rotate(24deg);
        opacity: 1;
      }
      .connect-icon-label {
        opacity: 1;
      }
    }
  }

  .icon{
    $size: 24px;

    @include icon-sprite('../assets/images/icons_connect', $size, 1, 5 2, $list: $connect-icons, $extension: svg, $skip-retina: true);
    display: inline-block;
    position: relative;
    opacity: 0.8;
    transition: transform $trans, opacity $trans;
  }

  &-icon-label {
    position: absolute;
    bottom: -16px;
    left: 50%;
    color: inherit;
    font-size: 0.65em;
    letter-spacing: 0.04em;
    white-space: nowrap;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity $trans;
  }

  @include mid-high-min {
    &.icons-large {
      $size: 32px;
      .icon {
        width: $size;
        height: $size;
      }
    }
  }
}
