$fallbacks: Helvetica, Arial, sans-serif;
$headertext: 'KingBasil', $fallbacks;
$bodytext: 'Muli', $fallbacks;
$accent: #25A8DF;

$base-bg: #333;
$page-bg: #fafafa;
$banner-bg: #e8e7e7;
$banner-bg-accent: rgba(lighten($banner-bg, 6%), 0.85);

$light-text: rgba(#fff, 0.9);
$dark-text: rgba(#666, 0.9);

$day-base: #AAE8FF; // darker color
$day-accent: #F7EFD9;  // lighter color
$day-text: $dark-text;
$day-accent-text: #80c7cc;
$day-thumb: #bee7f1;

$afternoon-base: #67b9ff;
$afternoon-accent: #f1fcff;
$afternoon-accent-text: #8ac7ff;
$afternoon-thumb: #c0e2ff;

$evening-base: #4953d0;
$evening-accent: #FF9881;
$evening-accent-text: #b58ffb;
$evening-thumb: #8c92de;

$night-base: #060421;
$night-accent: #0448AF;
$night-accent-text: #0448AF;
$night-thumb: #032872;
$night-text: $light-text;

$midnight-base: #0A0013;
$midnight-accent: #060A73;
$midnight-accent-text: #3d2f98;
$midnight-thumb: #10045f;

$border-color-default: rgba(0, 0, 0, 0.2);
$border-default: 1px solid $border-color-default;

$selection-background: $day-base;

// constants
$side-padding: 30px;

$max-cap: 900px;
@mixin max-cap{
  max-width: $max-cap;
  margin: 0 auto;
}

$nav-height: 40px;

$connect-icons: (
  contact,
  linkedin,
  github,
  codepen,
  resume
);
