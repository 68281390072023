$mid-low-breakpoint: 480px;
$mid-high-breakpoint: 680px;
$max-breakpoint: 900px;
$wide-breakpoint: 1060px;

@mixin mid-high-max {
  @media screen and (max-width: $mid-high-breakpoint - 1px) {
    @content;
  }
}

@mixin mid-high-min {
  @media screen and (min-width: $mid-high-breakpoint) {
    @content;
  }
}

@mixin min-height($min: 300px){
  @media screen and (min-height: $min) {
    @content;
  }
}

@mixin mid-high-max-min-height($min: 550px){
  @media screen and (min-width: $mid-high-breakpoint) and (min-height: $min) {
    @content;
  }
}

@mixin wide-min {
  @media screen and (min-width: $wide-breakpoint) {
    @content;
  }
}
