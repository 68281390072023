@import "./icon_sprite";

// set page background colors
@mixin page-background($base-color, $accent-color: null) {
  @if$accent-color {
    background-size: 350%;
    background-image: radial-gradient($accent-color, $base-color);
    animation: gradient-fade 10s infinite alternate;
  }
  background-color: $base-color;
}

// set container color
@mixin container-alternating-color($color, $base: 6%) {
  &:nth-of-type(even) { background-color: darken($color, $base); }
}

@mixin hover-focus-helper($skip-focus: false) {
  &:focus {
    outline: none;
  }

  @if($skip-focus == false) {
    &:focus, &:active {
      @content;
    }
  }

  @media screen and (hover: hover) {
    &:hover {
      @content;
    }
  }
}


@mixin icon-arrow($arrow-width, $dir: 'down', $path-depth: 1){
  $path: '../assets/images/arrow';
  @if($path-depth == 2) {
    $path: '../../assets/images/arrow';
  }

  cursor: pointer;
  opacity: 0.3;
  transition: opacity 300ms;
  @include icon-sprite($path, $arrow-width, 1, 1 2, $list: null, $extension: svg, $skip-retina: true);
  @include hover-focus-helper { opacity: 1; }

  @if($dir == 'up') {
    transform: scaleY(-1);
  }

  @if($dir == 'left') {
    transform: rotate(90deg);
  }

  @if($dir == 'right') {
    transform: rotate(-90deg);
  }
}
