@import "../../../assets/styles/mixins/mixins";

.index-toggle-root {
  $arrow-size: 20px;
  $offset: 0.2em;
  $hover-offset: 5px;

  display: flex;

  &.hide-title {
    font-size: 0;
  }

  @include hover-focus-helper {
    .arrow {
      &-left {
        transform: translateX(-$hover-offset);
      }
      &-right {
        transform: translateX($hover-offset);
      }
    }
  }
  .arrow {
    transition: transform 250ms;
    &:before {
      content: "";
      display: block;
    }
    &-left {
      margin-right: $offset;
      &:before {
        @include icon-arrow($arrow-size, $dir: 'left', $path-depth: 2);
      }
    }

    &-right {
      margin-left: $offset;
      order: 2;
      &:before {
        @include icon-arrow($arrow-size, $dir: 'right', $path-depth: 2);
      }
    }
  }
}
