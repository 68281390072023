@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/global/_style_elements";
@import "../../assets/styles/mixins/mixins";
@import "../../assets/styles/mixins/font_helpers";

@include set-font-face('KingBasil', 'King-Basil-Lite');
@include set-font-face($font-family: 'Muli', $file-name: 'Muli-Regular');

html, body{ width: 100%; height: 100%; }

body {
  margin: 0;
  color: $day-text;
  font-family: $bodytext;
  font-size: 16px;
  background-color: $base-bg;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
}

h1, h2, h3{
  font-size: 1.9em;
  display: inline-block;
  font-family: $headertext;
}

h4, h5{
  font-size: 1.1em;
  line-height: 1.3em;
}

h1 {
  &.hide {
    opacity: 0;
    height: 0;
    margin: 0;
    display: block;
  }
}

h3 { margin: 0 10px 0 0; }

h6 { font-size: 1em; }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  $trans: 250ms;
  $color: $day-text;

  position: relative;
  text-decoration: none;
  cursor: pointer;
  color: $color;
  &:not(.skip-link-style) {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      opacity: 0;
      background-color: $color;
      transform: translateY(3px) scaleX(0.5);
      transition: opacity ($trans * 0.2) ($trans * 0.4), transform $trans;
    }

    @include hover-focus-helper {
      &:after {
        opacity: 1;
        transform: translateY(1px) scaleX(1);
        transition: opacity ($trans * 0.1), transform $trans;
      }
    }
  }
}

p, li, .caption {
  font-size: 0.8em;
  font-weight: 200;
  line-height: 1.4em;
  letter-spacing: 0.03em;
  display: block;
}

img { max-width: 100%; }

.app {
  &-wrapper {
    opacity: 0;
    transition: opacity 800ms;
    &:not(.loaded) {
      height: 100vh;
      overflow: hidden;
    }
    &.loaded {
      opacity: 1;
      .main-container { opacity: 1; }
    }
  }
}

.content-container {
  position: relative;
  &:not(.skip-padding) { padding: $side-padding; }
}

@media screen and (min-width: $mid-high-breakpoint){
  h4, h5{
    font-size: 1.3em;
    line-height: 1.5em;
  }
}

::selection {
  background: $selection-background;
}

::-moz-selection {
  background: $selection-background;
}
