@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/global/_style_elements";
@import "../../assets/styles/mixins/mixins";

.browse {
  .content-container {
    @include container-alternating-color($page-bg, $base: 0%);

    &.skills{
      padding-top: $side-padding + 10px;
      .content-wrapper{ @include max-cap; }
      .section-wrapper{
        display: flex;
        justify-content: space-around;
        .section{ flex: 1 1; }
      }
    }
  }

  .arrow{
    $arrow-offset: 40px;
    $arrow-width: 30px;

    @include icon-arrow($arrow-width);
    position: absolute;
    left: 50%;
    top: calc(100vh - #{$arrow-offset});
    height: 12px;
    transform: translateX(-50%);
  }

  @include min-height {
    .header.content-container {
      height: 62vh;
      .header-container {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 300px;
        transform: translate(-50%, -50%);
      }
    }
  }

  @include mid-high-min {
    .content-container {
      &.fill-screen {
        $padding-offset: ($side-padding * 2);

        height: 100vh;
      }

      &.skills{
        .section{
          $item-size: percentage(1/3);

          &:first-child{ padding-right: $side-padding; }
          &:last-child{ padding-left: $side-padding; }
          h5{
            text-align: center;
          }
          .items{
            display: flex;
            flex-wrap: wrap;
          }
          p{
            flex: 1 1 $item-size;
            max-width: $item-size;
            padding-right: 16px;
            box-sizing: border-box;
          }
        }
      }

      &.about {
        font-size: 1.15em;
      }
    }
  }

  @include mid-high-max-min-height {
    .content-container.fill-screen {
      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
