.header-container.nav.show {
  display: none !important;
}

.hide-footer + footer {
  display: none;
}

img.nick{
  width: 100%;
}