@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/global/_style_elements";
@import "../../assets/styles/mixins/mixins";

footer {
  position: relative;
  padding: ($side-padding * 0.7) $side-padding $side-padding;
  text-align: center;
  border-top: 1px solid rgba(#fff, 0.08);
  background-color: $day-thumb;
  box-sizing: border-box;

  .connect{
    display: flex;
  }
  .copyright{
    .date:before{
      content: attr(data-year);
    }
  }

  #top{
    position: relative;
    margin-bottom: 1em;
    text-align: center;
    transition: opacity 250ms 100ms, visibility 0ms 650ms;
    font-size: 0.8rem;
    & > div {
      cursor: pointer;
      display: inline-block;
      @include hover-focus-helper {
        .top-text{
          opacity: 1;
        }
      }
    }
  }

  .arrow {
    @include icon-arrow(20px, up);
    height: 8px;
    margin: 0 auto;
  }

  .top-text{
    margin: 0;
    padding: 0.05em 0 0;
    min-width: 100px;
    opacity: 0;
    transition: opacity 250ms;
  }

  @include mid-high-max {
    .connect{
      justify-content: center;
      margin-bottom: 1em;
    }
  }

  @include mid-high-min {
    $footer-tb-padding: 10px;
    $footer-offset: 2px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $footer-tb-padding;
    padding-bottom: $footer-tb-padding - $footer-offset;

    #top{
      position: relative;
      order: 2;
      margin: $footer-offset 0 0;
    }

    .connect, .copyright, & > a{
      flex: 1 1;
    }
    .connect{
      order: 3;
      justify-content: flex-end;
    }
    .copyright{
      order: 1;
      text-align: left;
    }
  }
}
