@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/global/_style_elements";

.content-container-grid{
  $content-spacing: 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: $side-padding - $content-spacing;

  & > div {
    $item-size: 50%;

    flex: 1 1 $item-size;
    max-width: $item-size;
    padding: $content-spacing;

    @include mid-high-min {
      $item-size: percentage(1/3);
      flex: 1 1 $item-size;
      max-width: $item-size;
    }

    @include wide-min{
      $item-size: 25%;

      flex: 1 1 $item-size;
      max-width: $item-size;
    }
  }
}
