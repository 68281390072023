@import "../../assets/styles/global/_breakpoints";
@import "../../assets/styles/global/_style_elements";

$intro-trans: 500ms;
$offset-x-start: 20%;

.detail {
  min-height: calc(100vh - #{$nav-height} - 53px);
  padding-top: $nav-height;

  &.item-ready {
    .content-header, .content-info, .sections, .paging {
      opacity: 1;
    }

    .content-info, .sections, .paging {
      transition-delay: ($intro-trans * 0.75);
    }
  }

  .content-header, .content-info, .sections, .paging {
    opacity: 0;
    transition: opacity $intro-trans;
  }

  .title {
    font-weight: 200;
    font-size: 1.7em;
    margin: 10px 0 0;
    line-height: 1.3em;
    color: saturate(darken($day-accent-text, 5%), 15%);
  }

  .content-header {
    text-align: center;
    padding-left: $side-padding;
    padding-right: $side-padding;
    padding-bottom: 16px;
    box-sizing: border-box;

    .project.title{ display: block; }
  }

  .content-container {
    font-size: 0;
    .content-header, .content-info {
      font-size: 1rem;
    }
  }

  .content-info {
    display: flex;
    flex-wrap: wrap;
    padding: $side-padding $side-padding 8vh;
    box-sizing: border-box;
    a {
      color: $day-accent-text;
      font-weight: 600;
      &:after {
        background-color: $day-accent-text;
      }
    }
    p { margin-top: 0; }
  }

  .meta-container {
    & > div + div {
      margin-top: 2em;
    }
    .heading { margin-bottom: 10px; }
  }

  .banner-container {
    position: relative;
    background-color: $banner-bg-accent;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      z-index: 0;
      background-image: linear-gradient(rgba($banner-bg, 0), rgba($banner-bg, 0.75));
    }
    & > div {
      position: relative;
      z-index: 1;
    }

    .paging {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 20px;
      font-size: 1rem;
      transform: translateY(-50%);
    }
  }

  .tools-container .tools {
    $offset: 4px;
    margin-left: -$offset;
    li {
      display: inline-block;
      margin: $offset;
      padding: 4px 8px;
      border: 1px solid rgba(#000, 0.1);
      border-radius: 2px;
    }
  }

  .section + .section {
    margin-top: 6vh;
  }

  .images {
    text-align: center;
  }

  @media screen and (min-width: 570px){
    .title { font-size: 1.2em; margin: 0; }
  }

  @media screen and (max-width: $mid-high-breakpoint - 1px) {
    &.item-ready {
      .banner {
        opacity: 1;
      }
    }

    .banner {
      opacity: 0;
      transition: opacity $intro-trans ($intro-trans * 0.3);
    }

    .desc + .tools-container {
      margin-top: 10px;
    }
    .meta-container {
      margin-top: 1.4em;
    }
    .banner-container {
      padding-top: 5%;
      padding-bottom: 3%;
    }
  }

  @media screen and (min-width: $mid-high-breakpoint) {
    padding-bottom: 4%;

    &.item-ready {
      .banner-container .banner {
        transform: translate(3%, -44%);
        opacity: 1;
      }
    }

    .banner-container {
      position: relative;
      overflow: hidden;
      & > div {
        position: absolute;
      }
      &:before {
        content: "";
        position: relative;
        display: block;
        padding-top: 50%;
      }

      .banner {
        top: 50%;
        right: -50px;
        max-width: 68%;
        transform: translate($offset-x-start, -44%);
        opacity: 0;
        transition: transform $intro-trans, opacity $intro-trans ($intro-trans * 0.2);
      }

      .content-header {
        left: 0;
        top: 52%;
        width: 39%;
        transform: translateY(-50%);
        .title {
          font-size: 5vw;
        }
      }
    }

    .content-header {
      padding-top: 20px;
      padding-bottom: 30px;
    }

    .content-info {
      @include max-cap;
      .desc{
        flex: 1 1 percentage(3/4);
        padding-right: 40px;
        box-sizing: border-box;
        font-size: 1.1em;
      }
      .meta-container { flex: 1 1 percentage(1/4); }
    }

    .images {
      max-width: 1160px;
      margin: 0 auto;
    }

    .section + .section {
      margin-top: 16vh;
    }

    .section .section-header + .images .asset.inline.half:nth-of-type(1) + .asset.inline.half:nth-of-type(2),
    .section .section-header + .images .asset.inline:not(.half) + .asset.inline:not(.half) {
      margin-top: 0
    }

    &.apt-list {
      .asset:not(.styled):not(.inline):not(.banner) img {
        max-width: 28%;
      }
    }

    // TODO: quick hardcode
    &.illustration .section:nth-of-type(2) .asset:not(.styled):not(.inline):not(.banner) img {
      max-width: 75%;
    }
  }
}
