.image-asset-root {
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 250ms;
  }
  &.animate {
    img, svg {
      transition: opacity 500ms;
    }
  }

  &.loaded {
    img {
      opacity: 1;
    }
    svg, .loading {
      opacity: 0;
    }
  }

  &.aspect {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    &:before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 75%;
    }

    &:not(.skip-bg) {
      background-color: rgba(#fff, 0.05);
    }

    &.square:before {
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }
}


