@import "../../assets/styles/global/_style_elements";

.error-container {
  position: relative;
  min-height: calc(100vh - #{$nav-height});
  padding-top: $nav-height;

  & > div {
    text-align: center;
    padding-top: 30vh;
    h3 { font-size: 2.5em; }
    h1 {
      display: block;
      max-width: 240px;
      margin: 0 auto;
      font-family: inherit;
      font-size: 1.2em;
    }
  }
}
