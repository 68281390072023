@import "../../../assets/styles/global/_breakpoints";
@import "../../../assets/styles/global/_style_elements";
@import "../../../assets/styles/mixins/mixins";

.content-item{
  $trans-dur: 350ms;

  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  .item{
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    color: $light-text;
    @include hover-focus-helper {
      .content-info{
        opacity: 1;
        .text{ transform: translateY(-50%); }
      }
    }
  }
  .wrapper{
    position: relative;
    padding-top: percentage(9/16);
  }
  .thumb, .content-info, .content-info:after, .aspect, .icon {
    position: absolute;
  }

  .thumb, .content-info, .content-info:after, .icon {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .thumb{
    background-color: $day-thumb;
  }

  .aspect {
    $size: 42%;

    top: 50%;
    left: 50%;
    width: $size;
    transform: translate(-50%, -50%);
    &:before {
      position: relative;
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  .icon {
    background-position: 50% 0;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .content-info{
    text-align: center;
    opacity: 0;
    background-position: center;
    background-size: cover;
    transition: opacity $trans-dur;
    &:after{
      content: "";
      background-color: rgba(0, 0, 0, 0.6);
    }
    .text{
      position: absolute;
      top: 50%;
      width: 100%;
      padding: 10px;
      z-index: 1;
      text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      transform: translateY(-30%);
      transition: transform $trans-dur * 1.75;
      .project{
        font-size: 1.3em;
        line-height: 1.5em;
      }
    }
  }

  @include mid-high-max {
    .content-info .content-desc{ display: none; }
  }

  @include wide-min{
    .content-info .text .project{ white-space: nowrap; }
  }
}
